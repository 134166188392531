<script>
  import { get } from 'vuex-pathify'
  import createGuardMixin from '@/helpers/createGuardMixin'
  import LoginForm from '@/components/account/LoginForm'
  import screen from '@/helpers/screen'

  const guardMixin = createGuardMixin(
    async ({ store, redirect }) => {
      if (await store.dispatch('account/loggedInAsync')) {
        await store.dispatch('config/ensureFresh')
        const marketplaceEnabled = store.get('config/marketplaceEnabled')
        if (marketplaceEnabled) {
          return redirect({ name: 'Market' })
        } else {
          return redirect({ name: 'Home' })
        }
      }
    },
    ['loggedIn']
  )

  export default {
    components: {
      LoginForm,
    },
    mixins: [guardMixin],
    computed: {
      screen,
      ...get('account', ['loggedIn']),
      subheadingCopy() {
        return `Log in to access box customization, explore the Add-On Market and the Essentials Shop, learn more about the products in your box, and adjust shipping and billing information.`
      },
    },
  }
</script>

<template>
  <section class="py-15">
    <BaseSpinner v-if="loggedIn" overlay="fixed" overlay-color="white" class="z-90" />
    <BaseContainer v-else max-width="lg">
      <div class="flex flex-col md:flex-row justify-between items-center">
        <div class="max-w-110 md:pr-10 flex flex-col items-center">
          <h1 class="font-heading font-bold text-5xl md:text-9xl leading-none">Member Login</h1>
          <h2
            class="font-heading text-xxl md:text-2xl leading-tight pt-2 pb-8 md:pb-0 md:pt-4 text-center md:text-left"
          >
            {{ subheadingCopy }}
          </h2>
        </div>
        <div class="max-w-100 bg-dawn-lt2 p-6">
          <p class="text-center -mb-4">
            Welcome back! Log in below to access <span class="inline-block">your account</span>.
          </p>
          <LoginForm>
            <template v-slot:buttonLabel>Log In</template>
          </LoginForm>
          <div class="mt-5 text-center">
            <BaseLinkStyled :to="{ name: 'AccountResetPassword' }" inline
              >Forgot Password?</BaseLinkStyled
            >
          </div>
          <BaseDivider class="my-6" :weight="2" color="black" />
          <BaseButton color="sunshine" :to="{ name: 'SubscribePlan' }"> Sign up now </BaseButton>
        </div>
      </div>
    </BaseContainer>
  </section>
</template>
